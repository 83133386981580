/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./variables.scss";

@font-face {
  font-family: Gravity;
  src: url(assets/font/Gravity-Regular.otf) format("opentype");
}

@font-face {
  font-family: Gravity-Book;
  src: url(assets/font/Gravity-Book.otf) format("opentype");
}

@font-face {
  font-family: Gravity-Light;
  src: url(assets/font/Gravity-Light.otf) format("opentype");
}


body {
  background-color: #ecf0f5;
  margin: 0;
  font-family: Gravity, serif !important
}

.box-shadow-bottom {
  .one-edge-shadow {
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
  }
}

.mat-card {
  box-shadow: 0 3px 2px 0px rgba(0,0,0,.2), 0 0px 5px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12) !important;
  border: 1px solid grey;
  //border-left: 3px solid #296282 !important;
}

.canvasjs-chart-credit {
  display: none;
}

.title-tile {
  .mat-figure {
    justify-content: left !important;
    margin-left: 5% !important;
  }

}
.mat-header-cell {
  flex-direction: column;
  justify-content: center;
}

.mat-cell {
  text-align: center;
  justify-content:center;
}

//.mat-progress-spinner circle, .mat-spinner circle {
//  stroke: rgb(0, 144, 255) !important;
//}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $main-purple !important;
}


.spinner {
  display: flex;
  justify-content: center;
  padding: 65px;
}

.w100 {
  width: 100%;
}

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

h1 {
  font-size: inherit;
  margin: 0;
  font-weight: normal;
}

.flex {
  display: flex;
}

h3 {
  font-size: 12px;
  margin: 0;
  font-weight: normal;
}

mat-cell:first-of-type, mat-footer-cell:first-of-type, mat-header-cell:first-of-type {
  padding-left: 0 !important;
}
mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
  padding-right: 0 !important;
}

.bordered-shadow {
  border: 1px solid grey;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2), 0 0 5px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}

.ngx-timepicker-control__input {
  background-color: transparent !important;
  font-size: 15px !important;
  font-family: inherit !important;
}

.ngx-timepicker-control {
  width: 45px !important;
}

.ngx-timepicker-control--active:after {
  background-color: transparent !important;
}

.ngx-timepicker__time-colon {
  margin: 0 !important;
}

.ngx-timepicker {
  border-bottom: 0 !important;
}

ngx-material-timepicker-toggle {
  display: none;
}

.mobile-card {
  width: 100%;
  height: 70px;
  margin-bottom: 5px;
  display: flex;
  background-color: white;
  border-bottom: 1px solid grey;
  border-top: 1px solid lightgrey;
  box-shadow: 0 1px 1px 0px lightgrey;
}

.forecast-card-vertical {
  width: 16%;
  min-width: 140px;
  height: 460px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  box-shadow: 0 3px 2px 0px rgb(0 0 0 / 20%), 0 0px 5px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%) !important;
}

app-sensors {
  .mat-form-field-flex {
    border-bottom: 1px solid !important;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .mat-cell {
    font-size: 11px;
  }
}

.mat-expansion-panel{
  box-shadow: none !important;
}

.gravity {
  font-family: Gravity, serif !important;
}

app-extremes, app-averages {
  .mat-card {
    padding: 16px 4px !important;
  }
}

app-statistics-chart, app-chartjs, app-bar-chart {
  .mat-card {
    padding: 16px 8px !important;
  }
}

.p1em {
  padding: 1em;
}

.mt1em {
  margin-top: 1em;
}


.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-card-title {
  font-size: 19px;
  font-weight: 500;
  margin-left: 8px;
}

.margin-az {
  margin: auto 0;
}

.margin-auto {
  margin: auto;
}
